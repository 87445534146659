import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../component/Navbar";
import axios from "axios";
import { API_BASE_URL } from '../config/api';

function Manufactured() {
  const [manufacturedMaterials, setManufacturedMaterials] = useState([]);
  const [manufacturedDetails, setManufacturedDetails] = useState([]);
  const [selectedImages, setSelectedImages] = useState({});

  useEffect(() => {
    const fetchManufacturedMaterials = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/manufactureMains/allManufactureMains`);
        const data = response.data;
        setManufacturedMaterials(data);

        // Initialize selectedImages state with the first image for each row
        const initialSelectedImages = {};
        data.forEach(material => {
          if (material.imageCarousel) {
            let imageArray = [];
            try {
              imageArray = JSON.parse(material.imageCarousel);
            } catch (error) {
              console.error("Error parsing imageCarousel JSON:", error);
            }
            if (imageArray.length > 0) {
              initialSelectedImages[material.id] = `${API_BASE_URL}/${imageArray[0]}`;
            }
          }
        });
        setSelectedImages(initialSelectedImages);

      } catch (error) {
        console.error("Error fetching manufactured materials:", error);
      }
    };

    const fetchManufacturedDetailsData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/manufactureDetails/allManufactureDetails`);
        const data = response.data;
        setManufacturedDetails(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchManufacturedMaterials();
    fetchManufacturedDetailsData();
  }, []);

  const handleImageClick = (materialId, imageUrl) => {
    setSelectedImages(prev => ({
      ...prev,
      [materialId]: imageUrl
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-800 to-blue-600 pb-16">
      <Navbar />
      <h2 className="text-[#b1becf] py-12 px-6 pt-32 mb-12 text-3xl lg:text-4xl font-bold text-center">
        Manufactured
      </h2>

      <div className="container mx-auto px-4 lg:px-8">
        {manufacturedMaterials.map((manufacturedMaterial) => {
          let imageArray = [];
          try {
            imageArray = JSON.parse(manufacturedMaterial.imageCarousel);
          } catch (error) {
            console.error("Error parsing imageCarousel JSON:", error);
          }

          return (
            <div key={manufacturedMaterial.id} className="mb-12 overflow-hidden">
              <div className="flex flex-col space-x-24 lg:flex-row">
                {/* Image Section */}
                <div className="w-full lg:w-1/3 pb-24 relative">
                  <img
                    src={selectedImages[manufacturedMaterial.id] || ''}
                    alt="Selected"
                    className="w-full h-full object-contain rounded-t-lg lg:rounded-t-none lg:rounded-l-lg transition-transform duration-300 ease-in-out"
                    style={{ objectFit: "contain" }}
                  />
                  <div className="absolute inset-x-0 bottom-0 border-t flex justify-center space-x-2 p-4 lg:p-6">
                    {imageArray.map((img, index) => (
                      <div
                        key={index}
                        className="w-16 h-16 md:w-20 md:h-20 cursor-pointer relative group"
                        onClick={() => handleImageClick(manufacturedMaterial.id, `${API_BASE_URL}/${img}`)}
                      >
                        <img
                          src={`${API_BASE_URL}/${img}`}
                          alt={`img${index + 1}`}
                          className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-300 ease-in-out group-hover:scale-105"
                        />
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                          <span className="text-white font-bold">View</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Details Section */}
                <div className="w-full lg:w-2/3 p-6">
                  {manufacturedMaterial.title && (
                    <div className="mb-4">
                      <h2 className="text-2xl lg:text-4xl font-bold text-white">
                        {manufacturedMaterial.title}
                      </h2>
                    </div>
                  )}

                  {manufacturedMaterial.desc && (
                    <div className="mb-4">
                      <p className="text-base lg:text-lg text-white">
                        {manufacturedMaterial.desc}
                      </p>
                    </div>
                  )}

                  {manufacturedMaterial.purpose && (
                    <div className="mb-4">
                      <p className="text-base lg:text-lg text-white">
                        {manufacturedMaterial.purpose}
                      </p>
                    </div>
                  )}

                  {manufacturedMaterial.specification && (
                    <div className="mb-4 w-2/3">
                      <img
                        src={`${API_BASE_URL}/${manufacturedMaterial.specification}`}
                        alt="Specification"
                        className="w-full object-contain shadow-lg rounded-lg"
                      />
                    </div>
                  )}

                  <div className="flex flex-wrap gap-4">
                    {manufacturedDetails.map(
                      (manufacturedDetail, index) =>
                        manufacturedDetail.machineId === manufacturedMaterial.id && (
                          <Link
                            key={index}
                            to={`/manufactured-details/${manufacturedDetail.id}?machineId=${manufacturedMaterial.id}`}
                          >
                            <button className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300 ease-in-out">
                              {manufacturedDetail.buttonName}
                            </button>
                          </Link>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Manufactured;
