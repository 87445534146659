


import React from 'react';
import worldmap from '../img/worldmap.png';

function Map() {
  return (
    <div className='bg-gradient-to-b from-transparent to-blue-200 bg-opacity-50 flex flex-col min-h-screen'>
      <div className='title'>
        <h2 className='text-[2rem] mt-12 font-bold text-center text-blue-900 mb-20'>
          Where Our Machinery Reaches
        </h2>
      </div>

      <div className='world-map'>
        <img src={worldmap} alt="map" />
        
        <div className='pin china sm:text-sm'>
          <span>CHINA</span>
        </div>
        <div className='pin india sm:text-sm'>
          <span>INDIA</span>
        </div>
        <div className='pin na sm:text-sm'>
          <span>NORTH AFRICA</span>
        </div>
        <div className='pin ea sm:text-sm'>
          <span>EAST AFRICA</span>
        </div>
        <div className='pin wa sm:text-sm'>
          <span>WEST AFRICA</span>
        </div>
        <div className='pin sa sm:text-sm'>
          <span>SOUTH AFRICA</span>
        </div>
      </div>
    </div>
  );
}

export default Map;
