



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';
// import imgback from '../img/image 1.png';
import { API_BASE_URL} from '../config/api'; 


function AboutPage() {
  const [aboutData, setAboutData] = useState(null);
  // console.log('aboutdata', aboutData)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/abouts/allAbouts`);
      console.log('data',response.data)
      setAboutData(response.data);
    } catch (error) {
      console.error('Error fetching about data:', error);
    }
  };

  console.log('aboutdata', aboutData)


  if (!aboutData) {
    return <div>Loading...</div>;
  }

  return (
 <div>
     <div
      className="about-page-container"
      style={{
        position: 'relative',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(2, 23, 75, 0.50)', 
          zIndex: 0.1,
        }}
      />
      <Navbar />
      <div style={{ position: 'relative', padding: '4rem 1rem', flex: '1' }}>
      {aboutData.length > 0 ? (
        aboutData.map((item) => (
          <div key={item.id} className="flex flex-col m-4 md:m-10 lg:m-20">
            <h2 className="text-white font-semibold lg:text-3xl text-2xl mb-6">
              {item.title || 'No title available'}
            </h2>
            <p className="text-white lg:font-semibold">
              {item.desc || 'No description available'}
            </p>
            <p className="text-white lg:font-semibold mt-4">
              {/* {item.subdesc || 'No sub-description available'} */}
            </p>
          </div>
        ))
      ) : (
        <div className="flex flex-col m-4 md:m-10 lg:m-20">
          <p className="text-white lg:font-semibold">
            No data available
          </p>
        </div>
      )}
    </div>
      {/* <Footer /> */}
    </div>
    <div>
      <Footer/>
    </div>
 </div>
  );
}

export default AboutPage;
