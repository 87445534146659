import React, { useState } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import axios from "axios";
import "./AboutPage.css";
import girlImg from '../img/pngwing.com (1).png';
import { API_BASE_URL } from "../config/api";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://mailsendksr.onrender.com/api/sendContactFormMail", formData);
      if (response.status === 200) {
        alert("Message sent successfully!");
        setFormData({ name: "", phone: "", email: "", message: "" });
      } else {
        alert("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("An error occurred while sending the message.");
    }
  };

  return (
   <div>
     <div className="contact-page-container">
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(2, 23, 75, 0.50)", // 50% opacity
          zIndex: 1,
        }}
      />
      <Navbar />
      <div style={{ position: "relative",  zIndex: 1, paddingTop: "4rem" }}>
        <div className="flex flex-col p-6  ">
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-gray-300 font-semibold lg:text-3xl text-2xl mb-6">
              GET CONNECTED
            </h2>
            <form
              className="bg-white flex flex-col items-center justify-center rounded-lg w-full max-w-xl lg:max-w-2xl p-4"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="text-gray-500 focus:outline-none mt-4 p-2 rounded-lg w-full bg-gray-200 pl-8"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                className="text-gray-500 focus:outline-none mt-4 p-2 rounded-lg w-full bg-gray-200 pl-8"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="text-gray-500 focus:outline-none mt-4 p-2 rounded-lg w-full bg-gray-200 pl-8"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                className="focus:outline-none text-gray-500 mt-4 p-2 rounded-lg w-full bg-gray-200 pl-8"
                value={formData.message}
                onChange={handleChange}
                required
              />
              <button className="text-white mt-6 p-2 rounded-lg px-12 text-center bg-blue-800">
                Send
              </button>
            </form>
            <img
              className="relative w-40 md:w-56 lg:bottom-20 md:bottom-20 bottom-14 left-20 md:left-40 lg:left-60 sm:ml-6"
              src={girlImg}
              alt="contact label"
              title="contact label"
            />
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start mt-10">
            <div className="mb-6 md:mb-0">
            <h2 className="text-white font-semibold text-xl mb-2">
                Main office:
              </h2>
              <p className="text-white">
                45, Lobito, Crescent Wuse 2 Abuja, FCT NIGERIA
              </p>
              <h2 className="text-white font-semibold text-xl mb-2 mt-8">
                Branch office:
              </h2>
              <p className="text-white">
               Plot-841, Road No.12 Krishnapur, Chutia, Ranchi, Jharkhand 834001
              </p>
              
            </div>
            <div className="mb-6 md:mb-0">
              <h2 className="text-white font-semibold text-xl mb-6">
                Email ID:
              </h2>
              <p className="text-white">ksrranchi@gmail.com</p>
              <p className="text-white">ksriil.info@gmail.com</p>
            </div>
            <div className="mb-6 md:mb-0">
              <h2 className="text-white font-semibold text-xl mb-6">
                Phone No:
              </h2>
              <p className="text-white">+2349130002999</p>
              <p className="text-white">+2349132000009</p>
              <p className="text-white">+2348054440247</p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
<div>
      <Footer />

</div>
   </div>
  );
}

export default Contact;
