import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Navbar from "../component/Navbar";
import axios from "axios";
import { API_BASE_URL } from "../config/api";

function ManufacturedDetails() {
  const { id: manufactureMaterialId } = useParams(); // This is the manufactureMaterial ID
  const [details, setDetails] = useState([]); // To hold all manufactured details
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const manufacturedDetailId = queryParams.get("machineId"); // This is the manufacturedDetail ID for scrolling

  const scrollRefs = useRef({}); // To hold references to each manufactured item

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/manufactureDetails/allManufactureDetails`);
      // Filter the manufactured details based on manufacturedDetailId
      console.log(response.data);
      
      const filteredDetails = response.data.filter(detail => detail.machineId === parseInt(manufacturedDetailId));
      setDetails(filteredDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [manufactureMaterialId]);

  useEffect(() => {
    if (!loading && details.length > 0) {
      // Scroll to the manufactured item corresponding to the manufacturedDetailId from the URL query params
      const element = scrollRefs.current[manufactureMaterialId];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [loading, manufactureMaterialId, details]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (details.length === 0) {
    return <div>No details found.</div>;
  }

  return (
    <div className="min-h-screen bg-[#0c4999] pb-16">
      <Navbar />
      <h2 className="text-[#b1becf] px-6 text-3xl font-semibold pt-28">
        Technical Specification
      </h2>
      <div>
        {details.map((detail) => (
          <div
            key={detail.id}
            ref={(el) => {
              scrollRefs.current[detail.id] = el;
            }}
            className="container mx-auto lg:flex flex flex-col text-white justify-between items-center mt-20"
          >
            <div className="lg:w-2/3 rounded-lg mx-8 p-4 py-12 text-white shadow-lg">
              <h2 className="text-white font-semibold text-3xl px-4">
                {detail.title || "Default Title"}
              </h2>
              <p className="text-gray-300 mt-4 px-4">
                {detail.description || "No description available."}
              </p>
              <div className="mt-4">
                <img
                  src={`${API_BASE_URL}/${detail.image}`}
                  alt={detail.title}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManufacturedDetails;
