


import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL} from '../config/api'; 


function Discover() {
  const [details, setDetails] = useState([]);
  const [selectedBox, setSelectedBox] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/discoverys/allDiscoverys`);
        setDetails(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ],
    beforeChange: (next) => setSelectedBox(next + 1)
  };

  const selectedDetail = details[selectedBox - 1] || {};

  return (
    <div className="flex flex-col items-center bg-gradient-to-b p-4 pb-12 from-blue-900 to-blue-500 overflow-hidden">
      <div className="text-center px-4 w-full lg:w-3/4 xl:w-2/3">
        <h2 className="text-2xl lg:text-4xl font-semibold mb-4 mt-16 text-white">
          Discover Our Machinery Expertise
        </h2>
        <div className="mt-8 w-full">
          <Slider {...settings}>
            {details.map((detail, index) => (
              <div key={index} className="box p-2">

              </div>
            ))}
          </Slider>

          {details.length > 0 && (
            <div className="mt-8 lg:mt-12 shadow-lg mx-auto w-full pb-12 lg:h-[500px] h-[550px] shadow-blue-100 flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-8 p-4 lg:p-8">
              <div className="text-left text-white px-4 lg:w-1/2">
                <h2 className="text-xl lg:text-2xl font-bold mb-4">{selectedDetail.title || 'No title available'}</h2>
                {/* <li className="text-sm lg:text-lg">{<div dangerouslySetInnerHTML={{__html: selectedDetail.desc}}></div> || 'No description available'}</li> */}
                <div className="list-disc pl-5">
  <div dangerouslySetInnerHTML={{ __html: selectedDetail.desc }} />
</div>

                
              </div>
              <div className="relative w-full md:w-[400px] sm:w-[300px] lg:w-1/2 h-auto p-2 rounded-lg flex flex-col items-center">
                {selectedDetail.image && (
                  <>
                    <img 
                      loading="lazy" 
                      src={`${API_BASE_URL}/${selectedDetail.image}`} 
                      className="w-full h-auto object-cover lg:h-[300px]" 
                      alt={selectedDetail.title} 
                    />
                    <img 
                      loading="lazy" 
                      src={`${API_BASE_URL}/${selectedDetail.image}`} 
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-32 md:mb-6 lg:mb-0 md:w-96 sm:w-72 lg:w-56 h-auto rounded-lg shadow-lg shadow-blue-200" 
                      alt={selectedDetail.title} 
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Discover;
