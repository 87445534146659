
import React, { useRef, useEffect, useState } from 'react';
import { GiAutoRepair } from "react-icons/gi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaScrewdriver } from "react-icons/fa";
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';

const Support = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.6,
  });

  const supportRef = useRef(null);
  const [supports, setSupports] = useState([]);

  // Fetch the support data
  useEffect(() => {
    const fetchSupports = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/supports/allSupports`);
        console.log("Supports fetched:", response.data); // Check the fetched data
        setSupports(response.data);
      } catch (error) {
        console.error('Error fetching supports:', error);
      }
    };

    fetchSupports();
  }, []);

  // Handle the scroll animation
  useEffect(() => {
    const supportElement = supportRef.current;

    const handleScrollAnimation = () => {
      if (supportElement && inView) {
        supportElement.classList.add('animate-visible');
      } else {
        supportElement.classList.remove('animate-visible');
      }
    };

    handleScrollAnimation();

    window.addEventListener('scroll', handleScrollAnimation);

    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
    };
  }, [inView]);

  return (
    <>
      <div id="support" ref={ref} className="bg-gradient-to-b from-transparent to-white bg-opacity-50 p-12 flex justify-center items-center min-h-screen">
        <div ref={supportRef} className="max-w-4xl w-full flex justify-center items-center">
          <div className="w-full">
            <h2 className="text-3xl font-semibold text-center text-white mb-20">Technical Support for Machinery</h2>

            <div className="flex justify-center items-center">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {/* Box 1 - Installation */}
                <div className={`box w-48 h-48 bg-blue-600 bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-black flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-right' : ''}`}>
                  <div className="text-4xl mb-2"><GiAutoRepair /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[0]?.title}</h3>
                </div>

                {/* Box 2 - Maintenance */}
                <div className={`box w-48 h-48 bg-white bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-black flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-right' : ''}`}>
                  <div className="text-4xl mb-2"><FaScrewdriver /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[1]?.title}</h3>
                </div>

                {/* Box 3 - Repair */}
                <div className={`box w-48 h-48 bg-blue-600 bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-white flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-bottom' : ''}`}>
                  <div className="text-4xl mb-2"><MdOutlineSupportAgent /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[2]?.title}</h3>
                </div>

                {/* Box 4 - Upgrades */}
                {/* <div className={`box w-48 h-48 bg-white bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-black flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-top' : ''}`}>
                  <div className="text-4xl mb-2"><MdOutlineSupportAgent /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[3]?.title}</h3>
                </div> */}

                {/* Box 5 - Training */}
                {/* <div className={`box w-48 h-48 bg-blue-600 bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-white flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-left' : ''}`}>
                  <div className="text-4xl mb-2"><MdOutlineSupportAgent /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[4]?.title}</h3>
                </div> */}

                {/* Box 6 - Support */}
                {/* <div className={`box w-48 h-48 bg-white bg-opacity-80 hover:bg-opacity-100 transition duration-500 ease-in-out rounded-lg text-black flex flex-col justify-center items-center p-4 ${inView ? 'animate-slide-right' : ''}`}>
                  <div className="text-4xl mb-2"><FaScrewdriver /></div>
                  <h3 className="text-sm text-center font-semibold leading-tight">{supports[5]?.title }</h3>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
